function switchCombination(elm) {
  var groups = elm.data('groups');
  for (var g in groups) {
    $('.product-variants [name="group['+g+']"]').val(groups[g]);
    $('.product-variants [name="group['+g+']"]').trigger('change');
  }
}
function restartIframeInModalContent() {
  $('.js-extraFieldPopup').each(function() {
    var $iframes = $(this).find('iframe');
    $(this).on('hidden.bs.modal', function () {      
      $iframes.each(function() {
        $(this).attr('src', $(this).attr('src'));
      });
    });
  });
}

$(document).ready(function() {
  $('body').on('click', '.js-switch-cbnt', function(e){
    e.preventDefault();
    switchCombination($(this));
    return false;
  });

  if ($('.js-product-combinations').length) {
    $('.js-product-variants').hide();
  }

  prestashop.on('updatedProduct', function (event) {
    if (event && event.id_product_attribute) {
      $('.js-switch-cbnt').removeClass('active');
      $('.js-switch-cbnt[data-id-product-attribute='+event.id_product_attribute+']').addClass('active');
    }
    if ($('.js-product-combinations').length) {
      $('.js-product-variants').hide();
    }
  });

  restartIframeInModalContent();
});