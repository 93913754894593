import $ from 'jquery';

$(document).ready(function () {
  var rtl = false;
  if (prestashop.language.is_rtl == '1') {
    rtl = true;
  }
  productBottomSlider(rtl);
});

function productBottomSlider(rtl) {
  var obj = '.js-crossselling-slider, .js-viewedproduct-slider, .js-accessories-slider, .js-category-products-slider, .js-featuredproducts-slider';
  $(obj).slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,
    infinite: true,
    speed: 1000,
    autoplay: false,
    dots: false,
    arrows: true,
    rtl: rtl,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
  });
  $(obj).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $(obj).find('.slick-active img.js-lazy, .slick-active img.js-simple-lazy').trigger("appear");
  });


  //soledis pack
  var objPack = '.js-pack-slider';
  $(objPack).slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    adaptiveHeight: true,
    infinite: false,
    speed: 1000,
    autoplay: false,
    dots: true,
    arrows: false,
    rtl: rtl,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ],
  });
  $(objPack).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $(objPack).find('.slick-active img.js-lazy, .slick-active img.js-simple-lazy').trigger("appear");
  });

  var objPack3 = '.js-pack-slider3';
  $(objPack3).slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,
    infinite: false,
    speed: 1000,
    autoplay: false,
    dots: true,
    arrows: false,
    rtl: rtl,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 992,
        settings: "unslick"
      }
      /*{
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }*/
    ],
  });
  $(objPack3).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $(objPack3).find('.slick-active img.js-lazy, .slick-active img.js-simple-lazy').trigger("appear");
  });

}