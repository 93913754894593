import $ from 'jquery';
import prestashop from 'prestashop';

$(document).ready(function () {
  createProductSpin();
  createInputFile();
  zoomImage();
  lightboxImage();
  coverImage();
  imageScrollBox();
  mobileImageScrollBox();
  displayRef();
  moveProductAttributes();
  addAccordionActiveClass();
  mailAlerts();
  sldDesktopAnchors();
  sldMoveMobileElements();
  sldMobileAnchors();
  sldMobileAddToCart();
  sldThumbVideo();

  $('body').on('change', '.product-variants *[name]', function () {
    togglePendingRefreshIcon('in');
  });

  prestashop.on('updatedProduct', function (event) {
    createInputFile();
    zoomImage();
    coverImage();
    imageScrollBox();
    mobileImageScrollBox();
    displayRef();
    moveProductAttributes();
    sldThumbVideo();

    if (event && event.product_minimal_quantity) {
      const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
      const quantityInputSelector = '#quantity_wanted';
      let quantityInput = $(quantityInputSelector);

      quantityInput.trigger('touchspin.updatesettings', {min: minimalProductQuantity});
    }
    
    $('#js_mfp_gallery').replaceWith(event.product_images_modal);
    lightboxImage();
    togglePendingRefreshIcon('out');
    mailAlerts();
  });

  if (typeof(varCustomActionAddToCart) !== 'undefined' && varCustomActionAddToCart) {
    $('body').off('click', '[data-button-action="add-to-cart"]');
    $('body').on('click', '[data-button-action="add-to-cart"]', function (event) {
      event.preventDefault();
      var $btn = $(this);
      var psAjaxCart = false;
      if (typeof(varPSAjaxCart) !== 'undefined' && varPSAjaxCart) {
        psAjaxCart = varPSAjaxCart;
      }

      if ($('#quantity_wanted').val() > $('[data-stock]').data('stock') && $('[data-allow-oosp]').data('allow-oosp').length === 0) {
        $btn.attr('disabled', 'disabled');
      } else {
        var _ret = (function () {
          var $form = $(event.target).closest('form');
          var query = $form.serialize() + '&add=1&action=update';
          var actionURL = $form.attr('action');

          function isQuantityInputValid($input) {
            var validInput = true;

            $input.each(function (index, input) {
              var $input = $(input);
              var minimalValue = parseInt($input.attr('min'), 10);
              if (minimalValue && $input.val() < minimalValue) {
                onInvalidQuantity($input);
                validInput = false;
              }
            });

            return validInput;
          };

          function onInvalidQuantity($input) {
            $input.parents('.product-add-to-cart').first().find('.product-minimal-quantity').addClass('error');
            $input.parent().find('label').addClass('error');
          };

          var $quantityInput = $form.find('input[min]');
          if (!isQuantityInputValid($quantityInput)) {
            onInvalidQuantity($quantityInput);

            return {
              v: undefined
            };
          }

          $btn.removeClass('added').addClass('disabled');

          $.post(actionURL, query, null, 'json').then(function (resp) {
            prestashop.emit('updateCart', {
              reason: {
                idProduct: resp.id_product,
                idProductAttribute: resp.id_product_attribute,
                idCustomization: resp.id_customization,
                linkAction: 'add-to-cart',
                cart: resp.cart
              },
              resp: resp
            });

            if (resp.success) {
              $btn.addClass('added');
              if (!psAjaxCart) {
                window.location.href = prestashop.urls.pages.cart + '?action=show';
              }
            }
            if (resp.hasError) {
              $('.js-modal-message-text').text(resp.errors[0]);
              $('.js-modal-message').modal('show');
            }
          }).fail(function (resp) {
            prestashop.emit('handleError', { eventType: 'addProductToCart', resp: resp });
          });
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    });
  }

  /*if( $('#product .mailalert-product-page').length ){

    $('.product-information .product-out-of-stock').append($('#product .mailalert-product-page').detach());

  }*/


});

function mailAlerts(){
  if( $('#product .product-additional-info .mailalert-product-page').length ){
    $('.product-information .product-out-of-stock').html($('#product .product-additional-info .mailalert-product-page').detach());
  }
  else{
    $('.product-information .product-out-of-stock').html('');
  }
}

function displayRef(){
  if( $('#product .js-product-attributes-source .product-reference span').length){
    $('#product .col_marque span.ref_value').html($('#product .js-product-attributes-source .product-reference span').html());
  }
  else{
    $('#product .col_marque span.ref_value').html('');
  }
}

var productResizeTimer;
$(window).resize(function() {
  clearTimeout(productResizeTimer);
  productResizeTimer = setTimeout(function() {
    zoomImage();
  }, 250);
});

const sldThumbVideo = () => {
  if (document.querySelector('body#product') !== null) {
    let thumbVideos = document.querySelectorAll('.js-thumb-video');
    let thumbImages = document.querySelectorAll('.js-thumb');
    let coverVideo = document.querySelector('.sld-jd-product-video-cover');
    if (thumbVideos.length > 0 && coverVideo !== undefined) {
      thumbVideos.forEach((thumb) => {
        thumb.addEventListener('click', (e) => {
          let video = e.currentTarget.getAttribute('data-video');
          coverVideo.innerHTML = video;
          coverVideo.classList.remove('invisible');
        });
      });
    }
    if (thumbImages.length > 0) {
      thumbImages.forEach((image) => {
        image.addEventListener('click', (e) => {
          let video = e.currentTarget.getAttribute('data-video');
          coverVideo.innerHTML = '';
          coverVideo.classList.add('invisible');
        });
      });
    }
  }
}

function togglePendingRefreshIcon(fade_status) {
  if (typeof(varProductPendingRefreshIcon) !== 'undefined' && varProductPendingRefreshIcon) {
    if (fade_status == 'in') {
      $('.js-product-refresh-pending-query').fadeIn();
    } else {
      $('.js-product-refresh-pending-query').fadeOut();
    }
  }
}

function zoomImage() {
  $('.zoomWrapper .js-main-zoom').css('position','static').unwrap();
  $('.zoomContainer').remove();

  $('.js-enable-zoom-image .js-main-zoom').elevateZoom({
    zoomType: 'inner',
    gallery: 'js-zoom-gallery',
    galleryActiveClass: 'selected',
    imageCrossfade: true,
    cursor: 'crosshair',
    easing: true,
    easingDuration: 500,
    zoomWindowFadeIn: 500,
    zoomWindowFadeOut: 300,
  });
}

function lightboxImage() {
  var $gallery = $('#js_mfp_gallery');
  if ($gallery.length) {
    var zClose = $gallery.data('text-close'),
        zPrev = $gallery.data('text-prev'),
        zNext = $gallery.data('text-next');

    $('.js_mfp_gallery_item').magnificPopup({
      type: 'image',
      tClose: zClose,
      tLoading: '<div class="uil-spin-css"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>',
      removalDelay: 500,
      mainClass: 'mfp-fade',
      closeOnBgClick: true,
      gallery: {
        enabled: true,
        tPrev: zPrev,
        tNext: zNext,
        tCounter: '%curr% / %total%',
      },
      image: {
        verticalFit: false,
      }
    });

    $('.js-mfp-button').bind('click', function() {
      var imageId = $('#js-zoom-gallery .js-thumb.selected').data('id-image');
      $('.js_mfp_gallery_item').filter('[data-id-image="' + imageId + '"]').trigger('click');
    });
  }
}

function coverImage() {
  $('.js-cover-image .js-thumb').on(
    'click',
    function (event) {
      $('.js-thumb.selected').removeClass('selected');
      $(event.currentTarget).addClass('selected');
      $('.js-qv-product-cover').prop('src', $(event.currentTarget).data('image'));
    }
  );
}

function imageScrollBox() {
  $('.js-product-thumbs-scrollbox').makeFlexScrollBox();
}
function mobileImageScrollBox() {
  $('.js-mobile-images-scrollbox').makeFlexScrollBox();
}


function showHideScrollBoxArrows() {
  var thumbsWidth = 0;
  $('.js-qv-product-images .js-thumb-container').each(function() {
    thumbsWidth = thumbsWidth + $(this).outerWidth();
  });

  if (($('.js-qv-product-images').width() + 5) < thumbsWidth) {
    $('.scroll-box-arrows').addClass('scroll');
  } else {
    $('.scroll-box-arrows').removeClass('scroll');
  }
}

function createInputFile()
{
  $('.js-file-input').on('change', function(event) {
    let target, file;

    if ((target = $(event.currentTarget)[0]) && (file = target.files[0])) {
      $(target).prev().text(file.name);
    }
  });
}

function createProductSpin() {
  const $quantityInput = $('#quantity_wanted');
  $quantityInput.TouchSpin({
    verticalbuttons: true,
    verticalupclass: 'material-icons touchspin-up',
    verticaldownclass: 'material-icons touchspin-down',
    buttondown_class: 'btn btn-touchspin js-touchspin',
    buttonup_class: 'btn btn-touchspin js-touchspin',
    min: parseInt($quantityInput.attr('min'), 10),
    max: 1000000
  });

  $('body').on('change keyup', '#quantity_wanted', function (e) {
    $(e.currentTarget).trigger('touchspin.stopspin');
    prestashop.emit('updateProduct', {
      eventType: 'updatedProductQuantity',
      event: e
    });
  });
}

function moveProductAttributes() {
  var $src = $('.js-product-attributes-source'),
      $dest = $('.js-product-attributes-destination'),
      $src2 = $('.js-product-availability-source'),
      $dest2 = $('.js-product-availability-destination');
  if ($src.length) {
    $dest.html($src.html());
    $src.remove();
  } else {
    $dest.empty();
  }
  if ($src2.length) {
    $dest2.html($src2.html());
    $src2.remove();
  } else {
    $dest2.empty();
  }
}

function addAccordionActiveClass() {
  $('.js-product-accordions [data-toggle="collapse"]').click(function() {
    $(this).closest('.panel').toggleClass('active');
  });
}

function sldDesktopAnchors() {
  if (!window.matchMedia("(max-width: 767.98px)").matches) {
    $('.sld_anchor a').click(function(e) {
      e.preventDefault();
      var anchor = $(this).attr('href').split('#')[1];
      $("html, body").animate({scrollTop: $('#block_'+anchor).offset().top }, 300);
    });
  }
}

function sldMoveMobileElements() {
  if (window.matchMedia("(max-width: 767.98px)").matches) {
    //description courte apres le prix
    if ($('.product-right-content .product-description-short').length > 0) {
      $('.product-right-content .product-description-short').prependTo('#product-description-short-mobile');
    }
    //label icons apres la description courte
    if ($('.displayLabels').length > 0) {
      $('.displayLabels').appendTo('#product-description-short-mobile');
    }
    //accessoires avant les onglets
    if ($('#tab_accessories').length > 0) {
      $('#tab_accessories').insertAfter('.main-content');
    }

    //creation des onglets
    $('.sld_anchor a.anchor').each(function(e){
      var anchor = $(this).attr('href').split('#')[1];
      $('#block_'+anchor).addClass('block_hidden');
      $('#block_'+anchor).insertAfter(this);
      //Description deroule
      if (anchor == 'description') {
        $(this).addClass('shown');
        $('#block_'+anchor).removeClass('block_hidden');
      }

    });
    $('.sld_tab.block_white').each(function(e){
      if (!$(this).is('#tab_top')) {
        $(this).addClass('sld_tab_hidden');
      }
    });
  }
}

function sldMobileAnchors() {
  if (window.matchMedia("(max-width: 767.98px)").matches) {
    $('.sld_anchor a.anchor').click(function(e) {
      e.preventDefault();
      var anchor = $(this).attr('href').split('#')[1];

      if ($(this).hasClass('shown')) {
        $('.sld_anchor a.anchor').removeClass('shown');
        $('.sld_block').addClass('block_hidden');
      }
      else {
        $('.sld_anchor a.anchor').removeClass('shown');
        $('.sld_block').addClass('block_hidden');

        $(this).addClass('shown');
        $('#block_'+anchor).removeClass('block_hidden');
        $("html, body").animate({scrollTop: $(this).offset().top-10 }, 600);
      }

    });
  }
}

let floatting_button = false;
function sldMobileAddToCart() {
  //add to cart flottant
  if (window.matchMedia("(max-width: 767.98px)").matches) {
    $(document).on( 'scroll', function(){
      var window_top = $(window).scrollTop();
      var $button = $('.product-add-to-cart-button button.add-to-cart');
      if ($button.length > 0) {
        var button_top = $button.offset().top + $button.height();

        if (window_top > button_top) {
          if (!floatting_button) {
            var bouton_flottant = '<a id="floatting_add_to_cart" class="btn" href="javascript:;">Ajouter au panier</a>';
            $('body').after(bouton_flottant);

            $('#floatting_add_to_cart').click(function(e){
              e.preventDefault();
              $button.trigger('click');
            });

            //pour chat et axeptio
            $('html').addClass('sld_floatting_button');

            floatting_button = true;
          }
        }
        else if (floatting_button) {
          $('#floatting_add_to_cart').remove();
          floatting_button = false;
        }
      }
    });
  }
}