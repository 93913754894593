$(window).load(function() {
    if ($('body#index').length > 0) {
        var selected_choice = $('.notre-selections-ancres .selected').data('choice');
        selectChoice(selected_choice);

        $('.notre-selections-ancres a[data-choice]').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            selected_choice = $(this).data('choice');
            selectChoice(selected_choice);
        });

        $('.nos-valeurs-moins').hide();
        $('.voir_plus a').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            $('.nos-valeurs-plus').hide();
            $('.nos-valeurs-moins').show();
            $(window).trigger('resize');
        });
        $('.voir_moins a').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            $('.nos-valeurs-moins').hide();
            $('.nos-valeurs-plus').show();
            $(window).trigger('resize');
        });
    }
});

function selectChoice(choice){
    $('.notre-selections-ancres a[data-choice]').removeClass('selected');
    $('.notre-selections-ancres a[data-choice="'+choice+'"]').addClass('selected');

    $('.notre-selection .elementor-widget-product-carousel').addClass('hide');
    $('.notre-selection .elementor-widget-product-carousel.'+choice).removeClass('hide');
    reloadSlickSlider(choice);
}

function reloadSlickSlider(choice){
    $('.notre-selection .elementor-widget-product-carousel .elementor-image-carousel').slick("unslick");

    var $carousel = $('.notre-selection .elementor-widget-product-carousel.'+choice+' .elementor-image-carousel');

    if ( ! $carousel.length ) {
        return;
    }

    var savedOptions = $carousel.data( 'slider_options' ),
        tabletSlides = 1 === savedOptions.slidesToShow ? 1 : 2,
        defaultOptions = {
            respondTo: elementorFrontend.isEditMode() ? 'min' : 'window',
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: savedOptions.slidesToShowTablet,
                        slidesToScroll: tabletSlides
                    }
                },
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: savedOptions.slidesToShowMobile,
                        slidesToScroll: 1
                    }
                }
            ]
        },

        slickOptions = $.extend( {}, defaultOptions, $carousel.data( 'slider_options' ) );

    $carousel.slick( slickOptions );
    $carousel.on('init', function(event, slick){
        $(window).trigger('resize');
    });
}