function updateSlickInTabs() {
  $('.aone-tabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var slick = $(e.target).attr('data-slickID');
    $('#'+slick).slick('setPosition');
    $('img.js-lazy').trigger('appear');
  });
}

function setHomeBlockSlider(obj, opt) {
  if( obj.parents('.block_id_2').length ){
    console.log('block_id_2');
    var nb_item_mobile = 1;
  }else{
    console.log('not block_id_2');
    var nb_item_mobile = 2;
  }
  $(obj).slick({
    slidesToShow: opt.slidesToShow,
    slidesToScroll: opt.slidesToShow,
    adaptiveHeight: false,
    infinite: true,
    speed: opt.speed,
    autoplay: opt.autoplay,
    dots: opt.dots,
    arrows: opt.arrows,
    rtl: opt.rtl,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: opt.slidesToShow_1220,
          slidesToScroll: opt.slidesToShow_1220,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: opt.slidesToShow_992,
          slidesToScroll: opt.slidesToShow_992,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: nb_item_mobile,
          slidesToScroll: nb_item_mobile,
        }
      }
    ],
  });

    // {
    //     breakpoint: 768,
    //         settings: {
    //     slidesToShow: opt.slidesToShow_768,
    //         slidesToScroll: opt.slidesToShow_768,
    // }


        $(obj).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $(obj).find('.slick-active img.js-lazy').trigger('appear');
  });
}

$(document).ready(function() {
  $('.js-home-block-slider').each(function() {
    setHomeBlockSlider($(this), $(this).data('slickoptions'));
  });

  updateSlickInTabs();
});