(function($) {
  $.fn.makeProductScrollBox = function() {
    if (this.length && !(this.hasClass('js-enabled-scrollbox'))) {
      var $scrollbox = this.find('.product-list'),
          $items = $scrollbox.find('.product-miniature'),
          $arrows = this.find('.scroll-box-arrows'),
          list_element = '.product-list-wrapper',
          list_item_element = '.product-miniature',
          auto_play = $scrollbox.data('autoscroll') || false;

      if (($scrollbox.width() + 20) < ($items.length * $items.outerWidth())) {
        this.addClass('js-enabled-scrollbox');

        $scrollbox.scrollbox({
          direction: 'h',
          distance: 'auto',
          autoPlay: auto_play,
          infiniteLoop: false,
          onMouseOverPause: auto_play,
          listElement: list_element,
          listItemElement: list_item_element,
        });
        $arrows.addClass('scroll');
        $arrows.find('.left').click(function () {
          $scrollbox.trigger('backward');
        });
        $arrows.find('.right').click(function () {
          $scrollbox.trigger('forward');
        });
        if ('ontouchstart' in document.documentElement) {
          $scrollbox.on('swipeleft', function() {
            $scrollbox.trigger('forward');
          });
          $scrollbox.on('swiperight', function() {
            $scrollbox.trigger('backward');
          });
        }
      }
    }
  };

  $.fn.makeFlexScrollBox = function(options) {
    var settings = $.extend({
      list: 'ul',
      items: 'li',
      arrows: '.scroll-box-arrows',
      autoPlay: false,
      onMouseOverPause: false,
      forceMakeScroll: false
    }, options );

    var $scrollbox = this,
        $items = $scrollbox.find(settings.items),
        $arrows = $scrollbox.next(settings.arrows);

    if ((($scrollbox.width() + 20) < ($items.length * $items.outerWidth())) || settings.forceMakeScroll) {
      $scrollbox.scrollbox({
        direction: 'h',
        distance: 'auto',
        autoPlay: settings.autoPlay,
        onMouseOverPause: settings.onMouseOverPause,
        infiniteLoop: false,
        listElement: settings.list,
        listItemElement: settings.items,
      });
      $arrows.addClass('scroll');
      $arrows.find('.left').click(function () {
        $scrollbox.trigger('backward');
      });
      $arrows.find('.right').click(function () {
        $scrollbox.trigger('forward');
      });
      if ('ontouchstart' in document.documentElement) {
        $scrollbox.on('swipeleft', function() {
          $scrollbox.trigger('forward');
        });
        $scrollbox.on('swiperight', function() {
          $scrollbox.trigger('backward');
        });
      }
    }
  };
 
}(jQuery));