function addBodyTouchClass() {
    if ('ontouchstart' in document.documentElement) {
        $('body').addClass('touch-screen');
    }
}

function stickyHeader() {
    var header_width = $('#header .main-header > .container').width(),
        $stickyMenu = $('[data-sticky-menu]'),
        $mobileStickyMenu = $('[data-mobile-sticky]');
    if (typeof(varStickyMenu) !== 'undefined' && varStickyMenu && $stickyMenu.length && header_width > 540) {
        $stickyMenu.sticky({
            wrapperClassName: 'desktop-sticky-wrapper'
        });

        $('[data-sticky-cart]').html($('[data-sticky-cart-source]').html());
    }
    if (typeof(varMobileStickyMenu) !== 'undefined' && varMobileStickyMenu && $mobileStickyMenu.length && header_width <= 540) {
        $mobileStickyMenu.sticky({
            wrapperClassName: 'mobile-sticky-wrapper'
        });
    }
}

function scrollToTopButton() {
    var $sttb = $('[data-scroll-to-top]');
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $sttb.fadeIn();
        } else {
            $sttb.fadeOut();
        }
    });

    $sttb.find('a').smoothScroll({
        speed: 500,
    });
}

function loadPageWithProcessBar() {
    if (typeof(varPageProgressBar) !== 'undefined' && varPageProgressBar) {
        Pace.start();
    }
}

function addTooltip() {
    $('body:not(.touch-screen) [data-toggle="tooltip"]').tooltip({
        position: { my: "center bottom-8", at: "center top" },
        hide: false,
        show: false,
    });
}

function loadSidebarNavigation() {
    $('[data-close-st-menu]').on('click', function(e){
        $('html').removeClass('st-menu-open st-effect-left st-effect-right');

        //bouton filtre sticky
        if($('#bouton_filtres').length > 0){
            $('.st-menu-right').removeClass('filtre_actif');
            $('#bouton_filtres').removeClass('d-none');
        }

    });

//    $('#js-header-phone-sidebar').removeClass('js-hidden').html($('.js-header-phone-source').html());
//    $('#js-account-sidebar').removeClass('js-hidden').html($('.js-account-source').html());
//    $('#js-contact-sidebar').removeClass('js-hidden').html($('.js-contact-source').html());
    $('#js-language-sidebar').removeClass('js-hidden').html($('.js-language-source').html());
    if ((typeof(varSidebarCart) !== 'undefined' && !varSidebarCart)) {
        $('#js-left-currency-sidebar').removeClass('js-hidden').html($('.js-currency-source').html());
    }

    $('[data-left-nav-trigger]').on('click', function(e){
        $('html').addClass('st-effect-left st-menu-open');
        return false;
    });
}

function loadSidebarCart() {
    //if (prestashop.page.page_name !== 'checkout' && prestashop.page.page_name !== 'cart') {
        if (typeof(varSidebarCart) !== 'undefined' && varSidebarCart) {
            $('#js-cart-sidebar').removeClass('js-hidden').html($('[data-shopping-cart-source]').html());
            $('[data-shopping-cart-source]').addClass('js-hidden');
            $('#js-currency-sidebar').removeClass('js-hidden').html($('.js-currency-source').html());

            $('[data-sidebar-cart-trigger]').on('click', function(e){
                $('#js-contact-sidebar').css('display','none');
                $('#js-customer-sidebar').css('display','none');
                $('#js-filters-sidebar').css('display','none');
                $('#js-cart-sidebar').css('display','block');
                $('html').addClass('st-effect-right st-menu-open');
                return false;
            });
            
            $('#js-customer-sidebar').removeClass('js-hidden').html($('.js-customer-source').html());
            $('#js-contact-sidebar').removeClass('js-hidden').html($('.js-contact-source').html());
            $('#js-filters-sidebar').removeClass('js-hidden').html($('.js-filters-source').html());
            
            $('[data-sidebar-customer-trigger]').on('click', function(e){
                $('#js-cart-sidebar').css('display','none');
                $('#js-contact-sidebar').css('display','none');
                $('#js-filters-sidebar').css('display','none');
                $('#js-customer-sidebar').css('display','block');
                $('html').addClass('st-effect-right st-menu-open');
                return false;
            });
            $('[data-sidebar-contact-trigger]').on('click', function(e){
                $('#js-cart-sidebar').css('display','none');
                $('#js-customer-sidebar').css('display','none');
                $('#js-filters-sidebar').css('display','none');
                $('#js-contact-sidebar').css('display','block');
                $('html').addClass('st-effect-right st-menu-open');
                return false;
            });
            $('[data-sidebar-filters-trigger]').on('click', function(e){
                $('#js-cart-sidebar').css('display','none');
                $('#js-customer-sidebar').css('display','none');
                $('#js-contact-sidebar').css('display','none');
                $('html').addClass('st-effect-right st-menu-open');
                return false;
            });
            
        }
       
    //}
}

function sidebarSubCategoriesTrigger() {
    var $subcats = $('.js-sidebar-categories');
    if ($subcats.length) {
        $subcats.find('.js-collapse-trigger').click(function(e) {
            $(this).next('.js-sub-categories').stop().slideToggle();
            $(this).find('.add').toggle();
            $(this).find('.remove').toggle();
        });
    }
}

function mobileMenuControl() {
  $('#mobile-menu-icon').on('click', function() {
    $('#dropdown-mobile-menu').stop().slideToggle();
  });
}

function updateCustomerDropdownMenu() {
    var $otherLinks = $('.js-otherCustomerDropdownLinks');
    if ($otherLinks.length) {
        $('.js-displayCustomerAccount a').each(function() {
            $(this).removeAttr('id').removeAttr('class').addClass('dropdown-item');
            var $span = $(this).find('span'),
                $i = $span.find('i');
            $span.removeAttr('class');
            $(this).remove('i');
            $(this).prepend($i);

            var $newThis = $(this).wrap('<li></li>').parent();
            $newThis.insertBefore($otherLinks);
        });
    }
}

function handleCookieMessage() {
    var $cookieMsg = $('.js-cookieMessage');
    if ($cookieMsg.length) {
        setTimeout(function(){
            $cookieMsg.cookieBar({
                closeButton : '.js-cookieCloseButton',
                path: prestashop.urls.base_url.substring(prestashop.urls.shop_domain_url.length)
            });
        }, 2000);
    }
}

function expandCategoryTree() {
    var currentCatID = $('.js-category-page').data('current-category-id');

    if (currentCatID !== undefined) {
        var $currentCatObj = $('.js-category-tree [data-category-id=' + currentCatID + ']'),
            $currentSBCatObj = $('.js-sidebar-categories [data-category-id=' + currentCatID + ']');

        $currentCatObj.addClass('current');
        $currentCatObj.parents('li').each(function() {
            $(this).children('[data-toggle="collapse"]').attr('aria-expanded', 'true');
            $(this).children('.category-sub-menu.collapse').addClass('show');
        });

        $currentSBCatObj.addClass('current');
        $currentSBCatObj.parents('li').each(function() {
            $(this).children('.js-sub-categories').show();
            $(this).find(' > .js-collapse-trigger .add').hide();
            $(this).find(' > .js-collapse-trigger .remove').show();
        });
    }
}

function addCustomDropdown() {
    $('.js-dropdown-toggle').each(function() {
        var $title = $(this).find('.dropdown-title'),
            $content = $(this).find('.dropdown-content');
        $title.click(function() {
            $content.stop().slideToggle();
        });
    });
}

function categoryDescriptionExpand() {
    var $catDesc = $('.js-expand-description');
    if ($catDesc.length) {
        let maxLines = 5;

        setDescSmallMaxHeight(maxLines);

        let maxHeight = $('.descSmall', $catDesc).height(),
            realHeight = $('.descFull', $catDesc).height();
        if (realHeight < (maxHeight+10)) {
            $('.descToggle.expand').css('display','none');
        }else{
            if (realHeight > maxHeight) {
                if(navigator.userAgent.indexOf("Firefox")!=-1){realHeight=realHeight+45};
                $catDesc.addClass('descCollapsed');
                $('.descSmall', $catDesc).css('max-height', 'none').height(maxHeight);

                $('.descToggle.expand a', $catDesc).on('click', function() {
                    $catDesc.removeClass('descCollapsed').addClass('descExpanded');
                    $('.descSmall', $catDesc).height(realHeight);
                    return false;
                });
                $('.descToggle.collapse a', $catDesc).on('click', function() {
                    $catDesc.addClass('descCollapsed').removeClass('descExpanded');
                    $('.descSmall', $catDesc).height(maxHeight);
                    return false;
                });
            }
        }
    }
}

function setDescSmallMaxHeight(max_lines){
    var lignes = 0;
    var margin = 0;
    var h1_height = $('h1.page-heading').outerHeight() + parseInt($('h1.page-heading').css('margin-bottom'));
    var heightIsSet = false;
    $('.descSmall p').each(function(){
        var p_height = $(this).outerHeight();
        var line_height = parseInt($(this).css('line-height'));
        var element_lignes =  p_height / line_height;
        if (element_lignes < max_lines && lignes < max_lines) {
            lignes += element_lignes;
            if (lignes < max_lines) {
                margin += parseInt($(this).css('margin-bottom'));
            }
            if (lignes >= max_lines && !heightIsSet) {
                var max_height = parseInt((20 * max_lines) + margin + h1_height);
                $('.descSmall').css('max-height', max_height+'px');
            }
        } else if (lignes == 0 && element_lignes >= max_lines){
            margin += parseInt($(this).css('margin-bottom'));
            var max_height = parseInt((20 * max_lines) + h1_height);
            $('.descSmall').css('max-height', max_height+'px');
            heightIsSet = true;
        }
    });
}

loadPageWithProcessBar();

$(document).ready(function() {
    addBodyTouchClass();

    stickyHeader();
    scrollToTopButton();

    loadSidebarNavigation();
    loadSidebarCart();

    updateCustomerDropdownMenu();

    sidebarSubCategoriesTrigger();
    mobileMenuControl();
    expandCategoryTree();
    categoryDescriptionExpand();

    addCustomDropdown();
});
$(window).load(function() {
    addTooltip();
    handleCookieMessage();
});

$(window).ready(function() {
    $('img.js-lazy').lazyload({
        failure_limit: 9999
    });
});